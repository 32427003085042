import { template as template_6d8f885d31384e309c6decd872b2ed09 } from "@ember/template-compiler";
const WelcomeHeader = template_6d8f885d31384e309c6decd872b2ed09(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
