import { template as template_b21f1ea365b44b769563656cd5925d68 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b21f1ea365b44b769563656cd5925d68(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
